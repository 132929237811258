/* ******************************************************** */
/* about-section */
.about-section {
  display: flex;
  margin: auto;
  margin-top: 180px;
  max-width: 1440px;
  padding: 0px 15px 0px 0px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 48px;
  }

  .left {
    flex: 1;

    img {
      width: 100%;
    }
  }

  .right {
    flex: 1;
    padding: 0px 100px;

    @media (max-width: 600px) {
      padding: 0px 24px;
    }

    .title {
      color: #314863;
      font-size: 44px;
      font-family: Montserrat;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.32px;
      margin-bottom: 12px;
      max-width: 448px;

      @media (max-width: 600px) {
        font-size: 28px;
      }
    }

    .details {
      color: #6d7682;
      font-size: 16px;
      font-family: Montserrat;
      line-height: 160%;
      letter-spacing: 0.12px;
      margin-bottom: 40px;
      max-width: 448px;
    }

    .number-wrapper {
      display: flex;
      max-width: 448px;

      .number-item {
        flex: 1;

        .number {
          color: var(--theme-color-2, #3087f1);
          font-size: 32px;
          font-weight: 700;
          line-height: 110%;
          letter-spacing: -0.32px;
        }

        .number-title {
          color: var(--neutral-black-100, #1a2533);
          font-size: 16px;
          font-family: Montserrat;
          line-height: 160%;
          letter-spacing: 0.12px;
        }
      }
    }
  }
}
