:root {
  --theme-color-1: white;
  --theme-color-2: #0c72ee;
  --theme-card-1: #64607d;
}

html {
  scroll-behavior: smooth;
}
body {
  background: var(--theme-color-1);
  color: var(--theme-color-2);
}
.landing {
  margin: auto;
  padding: 15px 0px 0px 0px;
}
