.footer-wrapper {
  background-color: #0c6ee6;

  .footer-section {
    max-width: 1120px;
    margin: auto;
    padding: 15px;
    padding: 64px 15px 112px;
    background: #0c6ee6;
    color: var(--theme-color-1);

    .section-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .logo-section {
        align-content: space-around;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
      }
      .social {
        display: flex;
        gap: 16px;
        align-content: space-around;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;

        img {
          cursor: pointer;
        }
      }
      .menu-options {
        display: flex;
        gap: 28px;
        align-content: space-around;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;

        .menu-item {
          color: #ffffff;
          font-size: 16px;
          font-weight: 600;
          line-height: 125%;
          letter-spacing: -0.24px;
          cursor: pointer;
        }
      }

      .mobile-footer-menu {
        display: flex;
      }

      .stay-in-touch {
        .title {
          color: #fff;
          font-size: 22px;
          font-weight: 700;
          line-height: 110%;
          letter-spacing: -0.32px;
          margin-bottom: 12px;
        }

        .input-wrapper {
          display: flex;
          gap: 16px;
          margin-bottom: 12px;

          input {
            display: inline-flex;
            padding: 16px 16px 16px 16px;
            align-items: center;
            border-radius: 12px;
            background: #3d8ef1;
            width: 272px;
            border: none;
            outline-offset: 0px !important;
            outline: none !important;
            color: #fff;
            font-size: 16px;
            font-family: Montserrat;
            line-height: 20px;
          }

          input::placeholder {
            color: #fff;
          }

          .btn-sign-up {
            color: #0c72ee;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 125%;
            display: flex;
            padding: 16px 50px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 12px;
            background: #fff;
            cursor: pointer;
          }
        }

        .signup-note {
          color: #eaf3fe;
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 0.12px;
          max-width: 424px;
        }
      }

      @media (max-width: 900px) {
        flex-direction: column;
        gap: 48px;

        .menu-options {
          flex: 1;
        }

        .stay-in-touch .input-wrapper .btn-sign-up {
          padding: 16px;
        }
      }

      @media (max-width: 500px) {
        .stay-in-touch .input-wrapper input {
          width: auto;
        }
      }
    }
  }
  .copyright,
  .rights-reserved {
    color: #eaf3fe;
    font-size: 16px;
    font-family: Montserrat;
    line-height: 160%;
    letter-spacing: 0.12px;
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    .footer-bottom,
    .menu-options {
      flex-direction: column;
      gap: 16px;
      text-align: left;
      align-content: baseline !important;
    }
    .social {
      justify-content: flex-start !important;
      gap: 16px;
      text-align: left;
      align-content: baseline !important;
    }
  }
}
