/* ******************************************************** */
/* happy-to-help */
.landing .happy-to-help {
  margin-top: 270px;
  margin-bottom: 260px;
  @media (max-width: 1300px) {
    margin-top: 225px;
    margin-bottom: 216px;
  }
  @media (max-width: 1100px) {
    margin-top: 187px;
    margin-bottom: 180px;
  }
  @media (max-width: 880px) {
    margin-top: 155px;
    margin-bottom: 150px;
  }
  @media (max-width: 750px) {
    margin-top: 103px;
    margin-bottom: 100px;
  }
  .will-pass {
    color: #00ba6d;
    text-align: center;
    font-size: 152px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    line-height: 115%;
    letter-spacing: -2.506px;
    text-transform: uppercase;
    @media (max-width: 1300px) {
      font-size: 126px;
    }
    @media (max-width: 1100px) {
      font-size: 105px;
    }
    @media (max-width: 880px) {
      font-size: 87px;
    }
    @media (max-width: 750px) {
      font-size: 58px;
    }
    @media (max-width: 500px) {
      font-size: 48px;
    }
    @media (max-width: 400px) {
      font-size: 40px;
    }
  }
  .main-text {
    color: #314863;
    text-align: center;
    font-size: 72px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    letter-spacing: -0.32px;
    @media (max-width: 1300px) {
      font-size: 60px;
    }
    @media (max-width: 1100px) {
      font-size: 50px;
    }
    @media (max-width: 880px) {
      font-size: 41px;
    }
    @media (max-width: 750px) {
      font-size: 27px;
    }
    @media (max-width: 500px) {
      font-size: 22px;
    }
    @media (max-width: 400px) {
      font-size: 18px;
    }
  }
}
