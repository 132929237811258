.header-wrapper {
  margin: auto;
  background: var(--theme-color-2);
  position: sticky;
  top: -1px;
  z-index: 11;
  padding: 0px 15px;

  .header {
    max-width: 1120px;
    margin: auto;
    display: flex;
    // position: sticky;
    // top: -1px;
    color: var(--theme-color-1);
    background: var(--theme-color-2);
    z-index: 11;
    padding: 22px 0px;

    @media (max-width: 700px) {
      padding: 12px 0px;
    }

    .logo {
      height: 36px;
      cursor: pointer;
    }

    .c-menu {
      flex: 1;
      display: flex;
    }

    .r-menu {
      display: flex;

      .r-menu-wrapper {
        text-align: center;

        .menu-items {
          display: flex;
          gap: 40px;
        }

        .menu-items .menu-item {
          font-size: 16px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: -0.24px;
          cursor: pointer;

          @media (max-width: 700px) {
            line-height: 0px;
          }
        }

        .menu-items .menu-item .btn.download {
          background: #e03131;
          border-radius: 10px;
          padding: 10px 25px;
        }
      }
    }

    .mobile-only {
      display: none;
    }

    .mobile-menu {
      position: fixed;
      top: 63px;
      right: 0px;
      left: 0px;
      bottom: 0px;
      background-color: var(--theme-color-2);
      visibility: hidden;
      opacity: 0;
      transition: visibility 2s, opacity 2.5s linear;
      display: flex;
      flex-direction: column;

      justify-content: center;
    }

    .mobile-menu.active {
      visibility: visible;
      opacity: 1;
    }

    .mobile-menu .menu-item {
      text-align: center;
      background-color: var(--theme-color-2);
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: -0.24px;
      margin-bottom: 72px;
    }

    .mobile-menu .menu-item:last-child {
      border-bottom: 1px solid var(--theme-color-2);
    }

    @media (max-width: 700px) {
      .logo {
        padding-left: 15px;
        flex: 1;
      }

      .c-menu,
      .r-menu-wrapper .menu-items .menu-item.faq,
      .r-menu-wrapper .menu-items .menu-item .btn.download {
        display: none;
      }

      .mobile-only {
        display: unset;
      }
    }
  }
}
