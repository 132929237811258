.contact-us {
  max-width: 720px;
  margin: auto;
  padding: 128px 15px 220px 15px;
  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title {
    color: #314863;
    font-size: 44px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.32px;
    margin-bottom: 16px;
  }
  .two-input {
    display: flex;
    gap: 32px;
    margin-bottom: 32px;
  }
  @media (max-width: 600px) {
    .two-input {
      flex-direction: column;
      gap: 16px;
    }
  }
  .input-wrapper {
    flex: 1;
    .input-title {
      color: #6d7682;
      font-size: 14px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-variant: all-small-caps;
      line-height: 16px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }
    .input {
      width: 100%;
      input,
      textarea {
        width: 100%;
        box-sizing: border-box;
        border-radius: 12px;
        border: 1px solid #d4d4d4;
        background: #fff;
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        font-size: 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        outline-offset: 0px !important;
        outline: none !important;
        resize: none;
      }
      input::placeholder,
      textarea::placeholder {
        color: #aeb5be;
      }
      .error {
        border: 1px solid red;
      }
    }
  }
  .error {
    color: red;
  }
  .sumbit-error {
    color: red;
    text-align: right;
  }
  .captcha {
    text-align: center;
    margin-top: 32px;
    .error {
      text-align: center;
    }
  }
  .submit-section {
    display: flex;
    margin-top: 32px;
    .tnc {
      flex: 1;
      display: flex;
      gap: 8px;
      padding: 16px 0px;
      color: #6d7682;
      font-size: 16px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: 0.12px;
      b {
        color: #1a2533;
        font-weight: 600;
      }
      .icon {
        cursor: pointer;
        display: flex;
      }
      .unchecked {
        opacity: 0.5;
        filter: brightness(0.5) saturate(0%);
      }
      .error {
        color: red;
        b {
          color: red;
        }
      }
    }
    .btn-submit {
      .btn {
        cursor: pointer;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        border-radius: 12px;
        background: #2782f0;
        display: inline-flex;
        padding: 16px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
    @media (max-width: 600px) {
      margin-top: 16px;
      flex-direction: column;
      .tnc {
        padding-top: 0px;
      }
    }
  }
}
