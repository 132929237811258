/* ******************************************************** */
/* how-it-work */
.how-it-work {
  margin-top: 180px;

  .main-title {
    padding: 0px 15px;
    color: #314863;
    font-size: 44px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.32px;
    margin-bottom: 100px;
    text-align: center;
  }

  .steps {
    position: relative;
  }

  .mobile-how-it-work {
    display: none;
  }

  @media (max-width: 600px) {
    margin-top: 140px;

    .main-title {
      font-size: 28px;
      margin-bottom: 42px;
      text-align: left;
    }
  }

  .how-it-work-wrapper {
    padding: 15px;
    max-width: 1120px;
    margin: auto;
    position: relative;
    display: flex;
    z-index: 2;

    .work-item {
      flex: 1;

      img {
        margin: auto;
        display: flex;
        margin-bottom: 32px;
      }

      .title {
        color: #314863;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .sub-title {
        color: #6d7682;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.12px;
        max-width: 244px;
        text-align: center;
        margin: auto;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      text-align: left;
      gap: 40px;

      .work-item {
        display: flex;
        gap: 24px;
        flex: unset;
        justify-content: center;
        img {
          margin: unset;
        }
        .work-item-text-wrapper {
          flex: 1;
          justify-content: center;
          display: flex;
          gap: 4px;
          flex-direction: column;
          max-width: 250px;
          .title,
          .sub-title {
            text-align: left;
            margin: unset;
            max-width: 250px;
          }
          .title {
            font-size: 18px;
          }
          .sub-title {
            font-size: 13.5px;
          }
        }
      }
    }
  }

  .dashed-line-wrapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    @media (max-width: 600px) {
      display: none;
    }

    .dashed-line {
      height: 8px;
      width: 100%;
      background-image: linear-gradient(
        to right,
        #3087f1 0px,
        #3087f1 24px,
        transparent 24px,
        transparent 48px
      );
      background-repeat: repeat-x;
      background-size: 48px 8px;
      position: relative;

      .line-left-fad {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0px, -50%);
        background: transparent;
      }

      .line-right-fad {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0px, -50%);
        background: transparent;
      }
    }
  }
}
