/* ******************************************************** */
/* home-section */
.home-section-wrapper {
  position: relative;
  overflow: hidden;
  .home-section {
    display: flex;
    margin: auto;
    max-width: 1120px;
    padding: 0px 15px;
    padding-top: 103px;
    padding-bottom: 300px;
    @media (max-width: 1600px) {
      padding-top: 30px;
    }

    .left {
      width: 60%;
      padding-right: 15px;
      padding-top: 30px;
      z-index: 2;
    }

    .right {
      width: 40%;
      z-index: 1;
    }

    .right img {
      width: 1900px;
      position: absolute;
      top: -200px;
      left: 50%;
      right: 0;
      transform: translate(-50%, 0);
    }
    .mobile-intro {
      display: none;
    }
    @media (max-width: 1600px) {
      .right img {
        width: 1600px;
        top: -100px;
      }
    }
    @media (max-width: 1500px) {
      .right img {
        width: 100%;
        top: -50px;
      }
    }
    @media (max-width: 1400px) {
      .right img {
        width: 1400px;
        top: 0px;
      }
    }
    @media (max-width: 1200px) {
      padding-bottom: 0px;
      .right img {
        width: 100%;
        top: 00px;
        position: relative;
      }
      .mobile-intro {
        display: block;
      }
      .header-intro {
        display: none;
      }
    }
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 20px;
      padding-bottom: 0px;
      .title {
        margin: auto;
      }
      .sub-title-bottom {
        margin: auto;
      }
      .header-download-section {
        justify-content: center;
      }
    }

    .left {
      .sub-title-top {
        color: #314863;
        font-size: 44px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.32px;
      }

      .sub-title-top span {
        color: #00ba6d;
      }

      .title {
        color: var(--theme-color-2);
        font-size: 74px;
        font-weight: 800;
        line-height: 130%;
        text-transform: uppercase;
        max-width: 555px;
      }

      .sub-title-bottom {
        color: #6d7682;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.12px;
        max-width: 555px;
      }

      @media (max-width: 600px) {
        .sub-title-top {
          font-size: 28px;
          text-align: center;

          img {
            width: 24px;
          }
        }

        .title {
          font-size: 44px;
          text-align: center;
        }

        .sub-title-bottom {
          font-size: 16px;
          text-align: center;
        }
      }

      .header-download-section {
        margin-top: 36px;
        display: flex;
      }

      .header-download-section .header-download-buttons {
        display: flex;
        gap: 25px;
      }

      .header-download-section .header-download-buttons .btn {
        cursor: pointer;
      }

      .header-download-section
        .header-download-buttons
        .btn.download:last-child {
        margin-top: 28px;
      }
    }

    @media (max-width: 1000px) {
      .landing .home-section {
        flex-direction: column-reverse;
      }

      .left {
        width: 100%;
        padding-right: 0px;
        padding-top: 20px;
      }

      .right {
        width: 100%;
      }
    }

    @media (max-width: 500px) {
      .header-download-section {
        display: block;
      }

      .header-download-section .header-check-list {
        margin-top: 20px;
      }

      .header-download-section .header-download-buttons {
        display: grid;
      }
    }
  }
}
