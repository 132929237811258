.prepare-exam {
  position: relative;
  padding-top: 163px;
  padding-bottom: 300px;
  overflow: hidden;
  .mobile-back {
    display: none;
  }
  .desk-back,
  .mobile-back {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    img {
      width: 1900px;
      position: absolute;
      top: 0px;
      left: 50%;
      right: 0;
      transform: translate(-50%, 0);
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
    }
  }
  @media (max-width: 600px) {
    padding-top: 170px;
    padding-bottom: 124px;
    .mobile-back {
      display: block;
      bottom: unset;
      bottom: 0;
      img {
        position: relative;
        width: 100%;
      }
    }
    .desk-back {
      display: none;
    }
  }

  .details {
    z-index: 2;
    position: relative;
    margin: auto;
    .sub-title {
      color: #1e2e41;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.12px;
      text-align: center;
      margin-bottom: 8px;
    }
    .title {
      color: #1e2e41;
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.32px;
      margin-bottom: 28px;
    }
    .download-buttons {
      display: flex;
      gap: 24px;
      justify-content: center;
    }
  }
}
