/* ******************************************************** */
/* faq-section */
.faq-section {
  max-width: 1120px;
  margin: auto;
  margin-bottom: 180px;
  padding: 15px;
  @media (max-width: 600px) {
    margin-bottom: 0px;
  }

  .title {
    color: #314863;
    font-size: 44px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.32px;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  .accordion {
    display: flex;
    gap: 28px;
    flex-direction: column;
    border: none;

    @media (max-width: 600px) {
      gap: 24px;
    }

    .accordion__item {
      border-radius: 16px;
      background: #f3f6fa;
      overflow: hidden;
      border: none;

      .accordion__button {
        padding: 28px;
        background: #f3f6fa;

        .faq-q {
          display: flex;

          span {
            color: #314863;
            font-size: 24px;
            font-family: Montserrat;
            font-weight: 700;
            line-height: 40px;
            flex: 1;

            @media (max-width: 600px) {
              font-size: 18px;
              line-height: 140%;
            }
          }
        }
      }

      .accordion__button::before {
        content: none;
      }

      .accordion__button:hover {
        background: #f3f6fa;
      }

      .accordion__panel {
        padding: 0px 28px 28px 28px;
        color: #6d7682;
        font-size: 16px;
        font-family: Montserrat;
        line-height: 160%;
        letter-spacing: 0.12px;
        background: #f3f6fa;

        div {
          max-width: 743px;
        }
      }
    }
  }
}

.accordion__button {
  box-sizing: border-box;
}
