.contact-us {
  max-width: 720px;
  min-height: calc(100vh - 727px);
  margin: auto;
  padding: 128px 15px 220px 15px;
  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title {
    color: #314863;
    font-size: 34px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.32px;
    margin-bottom: 16px;
  }
}
