/* ******************************************************** */
/* subscribe-section */
.subscribe-section {
  position: relative;
  overflow: hidden;
  padding: 15px;

  .back-color {
    position: absolute;
    border-radius: 100%;
    background: #3087f1;
    width: 2848.221px;
    height: 1327px;
    z-index: 1;
    top: -75%;
    left: 50%;
    transform: translate(-50%, 0px);

    @media (max-width: 600px) {
      top: -95%;
    }
  }

  .wrapper {
    z-index: 2;
    border-radius: 64px;
    background: #fff;
    position: relative;
    max-width: 1120px;
    margin: 148px auto;

    @media (max-width: 600px) {
      margin: 80px auto;
    }

    .title {
      color: #314863;
      font-size: 44px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.32px;
      padding-top: 48px;
      padding-bottom: 48px;
      text-align: center;

      @media (max-width: 600px) {
        font-size: 28px;
        padding-top: 32px;
        padding-bottom: 24px;
      }
    }

    .price-block {
      display: flex;

      .price-section {
        display: inline-flex;
        gap: 32px;
        margin: auto;
        margin-bottom: 52px;

        @media (max-width: 600px) {
          flex-direction: column;
          gap: 24px;
          text-align: center;

          .logo-icon img {
            display: flex;
            margin: auto;
          }
        }

        .price {
          text-align: center;
          font-size: 84px;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: 3px;

          small {
            font-size: 42px;
          }
        }

        .duration {
          color: #6d7682;
          font-size: 18px;
          line-height: 150%;
          letter-spacing: 0.12px;
        }
      }
    }

    .price-points {
      display: flex;
      max-width: 805px;
      margin: auto;
      margin-bottom: 27px;

      .point {
        flex: 1;
        display: flex;
        gap: 8px;

        .text {
          color: #314863;
          font-size: 22px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: -0.32px;
        }
      }

      @media (max-width: 600px) {
        margin-bottom: 24px;
        flex-direction: column;
        gap: 24px;
        padding: 0px 20px;

        img {
          width: 24px;
        }

        .point {
          .text {
            font-size: 18px;
            line-height: 140%;
          }
        }
      }
    }

    .purchase-through {
      margin-top: 21px;

      @media (max-width: 600px) {
        margin-top: 53px;
      }

      .purchase-through-title {
        color: #6d7682;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.12px;
        text-align: center;
      }

      .pay-options-wrapper {
        display: flex;

        .pay-options {
          display: inline-flex;
          gap: 36px;
          margin: auto;
          margin-top: 28px;
          margin-bottom: 48px;
        }
      }
    }
  }
}
